import { startOfMonth, endOfMonth, format, addDays } from "date-fns";

export class DateService {
  static dateFormat = "yyyy-MM-dd";

  static returnType(startDate, endDate) {
    return {
      startDate: format(startDate, this.dateFormat),
      endDate: format(endDate, this.dateFormat),
    };
  }

  static thisMonth() {
    const currentDate = new Date();
    return this.returnType(startOfMonth(currentDate), endOfMonth(currentDate));
  }

  static toDate(date) {
    const _date = new Date(date);
    return format(addDays(_date, 1), this.dateFormat);
  }

  static formatDate(date) {
    const _date = new Date(date);
    return format(_date, "dd-MM-yyyy");
  }
}
